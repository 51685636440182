@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
html {
    display: flex;
    flex-direction: column;
}

body {
    margin: 0;
    font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

a {
    text-decoration: none;
    color: #FFF;
    transition: all .1s ease-in-out;
}

.App {
    flex: 1 1;
    min-height: 100vh;
    text-align: center;
    background: url('careers-bg-radlink.jpg');
    background-size: cover;
    background-position: center;
    /* background: linear-gradient(to bottom, rgba(42, 91, 173, 1) 0%, rgba(100, 174, 214, 1) 100%); */
    color: #FFF;
}

.App.breastlink {
    background: linear-gradient(to bottom, #38B7E2, #0761AB);
    color: #fff;
}

.App.crc {
    background: linear-gradient(to bottom, #66C2E7, #6FCDF3);
    color: #FFF;
}

.App.corp {
    background: linear-gradient(to bottom, #CCC, #fff);
    color: #000;
}

.App.corp a {
    color: black;
}

.App.corp .content a {
    border: 2px solid black;
}

.App.corp.dark {
    color: white;
}

.App.corp.dark a {
    color: white;
}

.App.corp.dark .content a {
    border: 2px solid white;
}

.App.scriptsender {
    background: linear-gradient(to bottom, #10AEEF, #31B55F);
    color: #FFF;
}
.App.radsite {
    background: linear-gradient(to bottom, #13D6C0, #08799B);
    color: #FFF;
}

.App.azdrg {
    background: linear-gradient(rgba(255, 159, 10, 0.49), rgba(242, 119, 30, 0.97));
    color: #0a0a0a;
}
.App.azdrg a {
  color: #0a0a0a;
}
.App.dark.azdrg, 
.App.dark.azdrg a {
    color: #fff;
  }

.App.fnfifty {
    background: linear-gradient(to bottom, #dc3573, #c71c5c);
    color: #FFF;
}

.App.blny {
    background: linear-gradient(to bottom, #38B7E2, #0761AB);
    color: #fff;
}

.App.ror {
    background: linear-gradient(to bottom, #ffb043, #2a422a);
    color: #FFF;
}

.App.isg {
    background: linear-gradient(to bottom, #DB682C, #AA272F);
    color: #FFF;
}

.App.kern {
    background: linear-gradient(to bottom, rgb(26, 133, 233), #12477B);
}

.App.kern {
    background: linear-gradient(to bottom, rgb(26, 133, 233), #12477B);
}

.App.waveimaging {
    background: linear-gradient(to bottom, rgb(64, 72, 130), #3F8F5A);
}
.featured-story p{
    max-width: 600px;
    margin: 0 auto;
}
.content {
    max-width: 580px;
    margin: 0 auto;
    padding: 20px 30px;
  }
  .content.radnetimaging{
    max-width: unset;
  }
  .content .container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
  }
  .content .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;;
    width: 100%;
  }
  .content .col {
    margin: 0 1rem;
    max-width: 400px;
    flex: 1 1 33.33%;
  }
  @media (max-width: 1199px) {
    .content .col {
        margin: 0;
        flex: 1 1 100%;
        max-width: 100%;
        padding: 0 20px;
    }
  }
.radnetLogo {
    position: relative;
    width: 96px;
    height: 96px;
    display: block;
    margin: 0 auto 10px;
    overflow: hidden;
    padding-top: 40px;
}

.radnetLogo img {
    width: 0;
    min-width: 96px;
    min-height: 96px;
    border-radius: 50%;
}

.radnetLogo img {
    width: 0;
    min-width: 96px;
    min-height: 96px;
}
.houstonmedimaging .radnetLogo {
    height: 125px !important;
}
.houstonmedimaging .radnetLogo img{
    border-radius: 0 !important;
}
.App .footer {
    display: block;
    padding-top: 40px;
    font-size: .75em;
    padding-bottom: 20px;
    margin-bottom: 0px;
}

.App.dark {
    background-image: url('bg-radlink-dark.jpg');
    background-size: cover;
}

.App.dark .content a:hover {
    color: #000
}

.toggleTextMode {
    font-size: 11px;
    position: relative;
    top: -5px;
    padding-right: 5px;
}

.toggleColorContainer {
    display: inline-block;
    height: 22px;
    width: 20px;
    margin: 0 auto;
    background: #d6d6d6;
    border-radius: 2rem;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.toggleColorMode {
    display: block;
    background: #3c3b3d;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: 1px solid #d6d6d6;
    position: absolute;
    left: 1px;
    top: 0.5px;
    transition: left 0.2s ease-in-out;
    cursor: pointer;
}

.toggleColorMode.toggled {
    left: 19px;
}

@media only screen and (max-width: 768px) {
    img {
        max-width: 100%;
    }
    .featured-story {
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 1rem;
    }
    .content {
        padding: 0 30px;
    }
    .content.content.radnetimaging {
        padding: 0;
    }
    .content a {
        font-size: 15px;
        padding: 12px 20px;
        margin-bottom: 9px;
    }
}