.content a {
    width: 100%;
    display: block;
    border: 2px solid rgba(255, 255, 255, .5);
    font-family: OpenSans, Karla, karla, sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    padding: 15px 20px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-sizing: border-box;
}

.content a:hover {
    background: #FFF;
    color: #3d3b3c
}


